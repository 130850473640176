import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px text-black-lp-300 overflow-hidden h-screen w-full" }
const _hoisted_2 = {
  key: 0,
  class: "w-14/20 flex flex-row items-center justify-end space-x-4"
}
const _hoisted_3 = { class: "w-1/2" }
const _hoisted_4 = { class: "w-2/5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectSearch = _resolveComponent("SelectSearch")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_CargoProcessCargo = _resolveComponent("CargoProcessCargo")!
  const _component_ListReadyToCargo = _resolveComponent("ListReadyToCargo")!
  const _component_FirstStep = _resolveComponent("FirstStep")!
  const _component_SecondStep = _resolveComponent("SecondStep")!
  const _component_ThirdStep = _resolveComponent("ThirdStep")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DetailLayout, {
      onBack: _ctx.goBack,
      title: _ctx.progress === -1 ? 'Daftar Siap Dikargo' : _ctx.$t('Booking Kargo'),
      "vertical-separator": false,
      fullPage: "",
      "is-floating-action": false,
      "leave-page-confirmation": _ctx.leavePageConfirmation,
      "onUpdate:leave-page-confirmation": _cache[4] || (_cache[4] = ($event: any) => (_ctx.leavePageConfirmation = $event)),
      onLeavePage: _ctx.onLeavePage,
      onCancelLeavePage: _ctx.cancelLeavePage,
      isTab: _ctx.progress === -1,
      usePaddingVertical: `pt-2 pb-6`
    }, {
      "top-right": _withCtx(() => [
        (_ctx.progress === -1)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode("div", _hoisted_3, [
                _createVNode(_component_SelectSearch, {
                  isLoading: _ctx.isLoadingClientPartner,
                  options: _ctx.listClientParentData,
                  keyName: "display",
                  keyValue: "id",
                  placeholder: "Pilih klien",
                  modelValue: _ctx.clientValue,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => (_ctx.clientValue = $event)),
                    _ctx.onChangeClient
                  ],
                  "server-side": false
                }, null, 8, ["isLoading", "options", "modelValue", "onUpdate:modelValue"])
              ]),
              _createVNode("div", _hoisted_4, [
                _createVNode(_component_SelectSearch, {
                  onFocus: _ctx.fetchDestinationCity,
                  onFilter: _ctx.fetchDestinationCity,
                  isLoading: _ctx.isLoadingCity,
                  options: _ctx.optionDestinationCity,
                  keyName: "name",
                  keyValue: "code",
                  placeholder: "Pilih kota tujuan",
                  modelValue: _ctx.destinationCityRTC,
                  "onUpdate:modelValue": [
                    _cache[2] || (_cache[2] = ($event: any) => (_ctx.destinationCityRTC = $event)),
                    _ctx.chooseDestination
                  ]
                }, null, 8, ["onFocus", "onFilter", "isLoading", "options", "modelValue", "onUpdate:modelValue"])
              ]),
              _createVNode(_component_LpButton, {
                outline: "",
                textColor: "red-lp-100",
                title: "Booking Baru",
                fontWeight: "semibold",
                color: "red-lp-100",
                onClick: _ctx.goToManualBooking
              }, null, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isSelectSchedule)
          ? (_openBlock(), _createBlock(_component_LpButton, {
              key: 1,
              title: "Pilih jadwal",
              fontWeight: "semibold",
              disabled: _ctx.disabledScheduleOption || _ctx.disabledV2,
              textColor: "white",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showModalPlaneAuto(_ctx.tokenNgen)))
            }, null, 8, ["disabled"]))
          : _createCommentVNode("", true),
        (_ctx.isButtonTopRight)
          ? (_openBlock(), _createBlock(_component_LpButton, {
              key: 2,
              textColor: "white",
              title: _ctx.$t(_ctx.titleButtonTopRight),
              fontWeight: "semibold",
              onClick: _ctx.goToThirdStep,
              disabled: 
            !_ctx.isDisableFindCargo || _ctx.isErrorDiffPiecesActualThanDimensionPieces
          
            }, null, 8, ["title", "onClick", "disabled"]))
          : _createCommentVNode("", true),
        (_ctx.progress === 2)
          ? (_openBlock(), _createBlock(_component_CargoProcessCargo, {
              key: 3,
              onLeavePage: _ctx.onLeavePage
            }, null, 8, ["onLeavePage"]))
          : _createCommentVNode("", true),
        (
            _ctx.progress === 0 &&
              (_ctx.isRebookingRTC && _ctx.form.cargoType === 'plane'
                ? _ctx.form.flightsData.length
                : _ctx.isRTC || (_ctx.isRebookingRTC && _ctx.form.cargoType !== 'plane')) &&
              _ctx.progress !== 1
          )
          ? (_openBlock(), _createBlock(_component_CargoProcessCargo, {
              key: 4,
              onLeavePage: _ctx.onLeavePage,
              onOnCheckCutOffEditRTC: _ctx.onCheckCutOffEdit
            }, null, 8, ["onLeavePage", "onOnCheckCutOffEditRTC"]))
          : _createCommentVNode("", true)
      ]),
      data: _withCtx(() => [
        (_ctx.progress === -1)
          ? (_openBlock(), _createBlock(_component_ListReadyToCargo, {
              key: 0,
              onLeavePage: _ctx.onLeavePage
            }, null, 8, ["onLeavePage"]))
          : (_ctx.progress === 0)
            ? (_openBlock(), _createBlock(_component_FirstStep, { key: 1 }))
            : (_ctx.progress === 1)
              ? (_openBlock(), _createBlock(_component_SecondStep, { key: 2 }))
              : (_ctx.progress === 2)
                ? (_openBlock(), _createBlock(_component_ThirdStep, { key: 3 }))
                : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onBack", "title", "leave-page-confirmation", "onLeavePage", "onCancelLeavePage", "isTab"])
  ]))
}