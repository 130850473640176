import { CargoController } from "@/app/ui/controllers/CargoController";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { CargoDetailStt } from "@/domain/entities/Cargo";
import { FlagsPermissionCargo } from "@/feature-flags/flags-permission-cargo";
import { Vue } from "vue-class-component";

export default class CargoPlaneAutoV2 extends Vue {
  get isFeatureFlightV2Enable(): boolean {
    return !!(
      FlagsPermissionCargo.feature_flight_v2_enable?.isEnabled() &&
      this.progress === 0 &&
      this.flowBooking === "manual" &&
      this.listStt?.length
    );
  }

  get selectedCargoTypeV2(): boolean {
    return !!(this.isFeatureFlightV2Enable && this.isPlaneAuto);
  }

  get progress(): number {
    return CargoController.progressValue;
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  get form() {
    return CargoController.form;
  }

  get isPlaneAuto() {
    return (
      this.form.cargoType === "plane" && this.form.cargoTypeStatus === "auto"
    );
  }

  get listStt(): CargoDetailStt[] {
    return CargoController.sttResultFinal;
  }

  get disabledV2(): boolean {
    return (
      (this.selectedCargoTypeV2 &&
        (CargoController.isErrorMaxGrossWeight ||
          CargoController.isErrorMaxVolumeWeight ||
          CargoController.isErrorMaxPieces)) ||
      this.disabledFormV2
    );
  }

  get disabledFormV2(): boolean {
    const {
      cargoProduct,
      actualTotalCargoGrossWeight,
      actualTotalCargoVolumeWeight,
      actualTotalSTTPieces
    } = this.form;

    return (
      !cargoProduct ||
      !actualTotalCargoGrossWeight ||
      !actualTotalCargoVolumeWeight ||
      !Number(actualTotalSTTPieces) ||
      this.disabledDimensionV2
    );
  }

  get disabledDimensionV2(): boolean {
    const { actualDimension } = this.form;

    return (
      !actualDimension?.width ||
      !actualDimension?.height ||
      !actualDimension?.length
    );
  }

  selectScheduleV2() {
    if (this.selectedCargoTypeV2) {
      CargoController.setShowSelectAirport(true);
      CargoController.setShowSelectAirportToLocalStorage(true);
      return true;
    }
    return false;
  }

  validationBackV2() {
    if (this.selectedCargoTypeV2) {
      CargoController.setCargoType({
        type: "",
        status: ""
      });
      this.form.cargoType = "";
      this.form.cargoTypeStatus = "";
      CargoController.setShowSelectAirportToLocalStorage(false);
      CargoController.setShowModalCargoType(false);
      return true;
    }

    return false;
  }
}
